// This is all you.

document.addEventListener("DOMContentLoaded", function(event) { 
    init_faq_accordion();
    init_inscription_state_checker();
    init_mobile_nav_scroll();
    init_mobile_nav_toggle();
});


let init_faq_accordion = () => {
    const faqs = document.querySelectorAll(".faq-el")
    if (faqs.length > 0) {
        faqs.forEach(faq => {
            faq.addEventListener("click", (e) => {
                faq.classList.toggle("active")
            })
        });
    }
}

let init_inscription_state_checker  = () => {
    const validation_switch = document.querySelector("#read-validation .switch")
    const btn = document.querySelector("#sending-btn")
    if (validation_switch != null) {
        validation_switch.addEventListener("click", () => {
            btn.disabled = !validation_switch.children[0].checked
        })
    }
}

let init_mobile_nav_scroll  = () => {
    window.addEventListener("scroll", () => {
        const html = document.querySelector("html")
        const mobileNav = document.querySelector("#mobile-nav")
        const backToTop = document.querySelector("#back-to-top")
        if (html.scrollTop > 300) {
            mobileNav.classList.add("visible")
            backToTop.classList.add("visible")
        } else {
            mobileNav.classList.remove("visible")
            backToTop.classList.remove("visible")
        }
    })
}

let init_mobile_nav_toggle  = () => {
    const menuToggleBtn = document.querySelector("#menuToggle")
    const mainNav = document.querySelector("#main-nav")
    menuToggleBtn.addEventListener("click", () => {
        menuToggleBtn.classList.toggle("active")
        mainNav.classList.toggle("active")
    })
}